<template>
  <v-dialog v-model="dialog" persistent width="550">
    <v-card :loading="loading" class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="'Make order'" @close="dialog = false" />
      <v-card-text>
        <div style="color: yellow">Your quota (used/total): {{ quota.quota_used }} / {{ quota.quota_sqkm }} (sqkm)</div>
        <v-radio-group v-model="downloadType" class="my-2" dense row>
          <v-radio label="Scene" value="scene"></v-radio>
          <v-radio label="Clip" value="clip"></v-radio>
        </v-radio-group>
        <v-text-field
          v-model="data.name"
          :rules="[rules.nameRequired]"
          dense
          label="Name"
          outlined
          placeholder="Name"
        ></v-text-field>
        <v-select
          v-model="data.productBundle"
          :items="productBundles"
          dense
          item-disabled="disable"
          item-text="name"
          item-value="key"
          label="Product Bundle"
          outlined
          placeholder="Product Bundle"
        >
          <template v-slot:selection="data">
            <v-layout>
              <div class="mr-3" v-html="data.item.name"></div>
              -
              <div v-if="data.item.auxiliaryFiles" class="text-uppercase ml-3" style="font-size: 12px;">
                (&nbsp;{{ data.item.auxiliaryFiles }}&nbsp;)
              </div>
            </v-layout>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-layout>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-spacer />
                  <v-layout
                    v-if="data.item.auxiliaryFiles"
                    class="text-uppercase"
                    justify-end
                    style="font-size: 12px; min-width: 100px;"
                  >
                    (&nbsp;{{ data.item.auxiliaryFiles }}&nbsp;)
                  </v-layout>
                </v-layout>
              </v-list-item-content>
            </template>
          </template>
        </v-select>
        <v-layout class="pt-5">
          <v-spacer />
          <v-btn
            :disabled="!data.name || !data.productBundle"
            :loading="loading"
            color="primary"
            rounded
            @click="submitData"
          >
            Make order
          </v-btn>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import PlanetApi from '@/utils/planet/planetTaskingApi'
import BUNDLES from '@/constants/bundles'
import ConvertDate from '@/utils/convertDate'

export default {
  components: { DialogHeader },
  data() {
    return {
      quota: {},
      rules: {
        nameRequired: value => !!value || 'Name is required',
      },
      bundles: [...BUNDLES],
      dialog: false,
      loading: false,
      image: undefined,
      downloadType: 'clip',
      geometry: undefined,
      data: {
        name: undefined,
        itemIds: [],
        type: undefined,
        productBundle: undefined,
        geometry: undefined,
      },
    }
  },
  watch: {
    dialog() {
      this.downloadType = 'scene'
    },
  },
  computed: {
    productBundles() {
      if (!this.image) return []
      let bundleAvailable = []
      this.bundles.forEach(bundle => {
        bundle.disable = false
      })
      if (this.downloadType === 'clip') {
        this.bundles.forEach(bundle => {
          bundle.disable = bundle.key.includes('basic_')
        })
      }
      let bundleByType = this.bundles.filter(val => val.assets[this.image.properties.item_type])
      bundleByType.forEach(val => {
        let check = true
        val.assets[this.image.properties.item_type].forEach(asset => {
          if (!this.image._permissions.toString().includes(asset)) check = false
        })
        if (check) bundleAvailable.push(val)
      })
      return bundleAvailable
    },
  },
  methods: {
    openDialog(image, geometry) {
      this.image = image
      this.geometry = geometry
      this.getQuota()
      this.dialog = true
      this.data.name =
        this.image.properties.item_type + '_' + ConvertDate.dateTimeFormatted(this.image.properties.acquired)
    },
    async getQuota() {
      try {
        this.loading = true
        const res = await PlanetApi.getQuota()
        this.quota = res[0]
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async submitData() {
      try {
        this.loading = true
        this.data.itemIds = this.image.ids ? this.image.ids : [this.image.id]
        this.data.type = this.image.properties.item_type
        if (this.downloadType === 'clip') this.data.geometry = this.geometry
        else this.data.geometry = undefined
        await PlanetApi.order(this.data)
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.$emit('refresh')
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.response.data.general[0].message)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
