var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"fill-height"},[_c('v-card',{staticClass:"custom-card-bg-1 overflow-y-auto overflow-x-hidden pa-2",attrs:{"height":"100%","width":"100%"}},[_c('v-layout',{attrs:{"column":"","fill-height":""}},[_c('div',{staticStyle:{"width":"100%","height":"50px"}},[_c('v-layout',{staticClass:"px-3 pt-2"},[_c('h3',{staticClass:"text-uppercase"},[_vm._v("ORDER LIST")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.addToTasking}},[_vm._v(" Create Order ")])],1)],1),_c('v-layout',{staticClass:"pa-3",staticStyle:{"overflow-y":"auto","flex":"1"},attrs:{"fill-height":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.orders,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalData,"fixed-header":"","height":"100%","hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!item.error && item.image_source === 'Skysat')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openDetail(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Info")])]):(item.error)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}],null,true)}):_vm._e()]}}],null,true)})],1),_c('div',{staticStyle:{"flex":"none","width":"100%"}},[_c('div',{staticClass:"mt-3 pb-2 d-flex justify-end"},[_c('v-pagination',{attrs:{"length":_vm.totalPage,"total-visible":10,"circle":""},on:{"input":_vm.getOrder},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1)],1),_c('PlanetOrders',{ref:"planetOrders"}),_c('CreateOrder',{ref:"createOrder"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }