<template>
  <v-layout class="fill-height">
    <v-card class="custom-card-bg-1 overflow-y-auto overflow-x-hidden pa-2" height="100%" width="100%">
      <v-layout column fill-height>
        <div style="width: 100%; height: 50px">
          <v-layout class="px-3 pt-2">
            <h3 class="text-uppercase">ORDER LIST</h3>
            <v-spacer />
            <v-btn color="primary" small @click="addToTasking">
              Create Order
            </v-btn>
          </v-layout>
        </div>
        <v-layout class="pa-3" fill-height style="overflow-y: auto; flex: 1">
          <v-data-table
            :headers="headers"
            :items="orders"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalData"
            class="elevation-1"
            fixed-header
            height="100%"
            hide-default-footer
            style="width: 100%"
          >
            <template #[`item.action`]="{item}">
              <v-tooltip v-if="!item.error && item.image_source === 'Skysat'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on" @click="openDetail(item)">
                    <v-icon small>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>Info</span>
              </v-tooltip>
              <v-tooltip v-else-if="item.error" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="error" dark v-bind="attrs" v-on="on">
                    mdi-alert-circle-outline
                  </v-icon>
                </template>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-layout>
        <div style="flex: none; width: 100%">
          <div class="mt-3 pb-2 d-flex justify-end">
            <v-pagination
              v-model="page"
              :length="totalPage"
              :total-visible="10"
              circle
              @input="getOrder"
            ></v-pagination>
          </div>
        </div>
      </v-layout>
    </v-card>
    <PlanetOrders ref="planetOrders" />
    <CreateOrder ref="createOrder" />
  </v-layout>
</template>
<script>
import ConvertDate from '@/utils/convertDate'
import { getTaskingOrders } from '@/api/tasking-api'
import PlanetOrders from '@/views/tasking-order/PlanetOrders.vue'
import CreateOrder from '@/views/createOrder/CreateOrder.vue'

export default {
  components: { CreateOrder, PlanetOrders },
  computed: {
    notificationId() {
      return this.$route.query?.noti
    }
  },
  data() {
    return {
      isNotification: false,
      currentData: {},
      loading: false,
      totalPage: 0,
      totalData: 0,
      orders: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Ref name', value: 'ref_name' },
        { text: 'Ref type', value: 'ref_type' },
        { text: 'Created at', value: 'created_at' },
        { text: '', value: 'action', sortable: false },
      ],
      options: {},
      page: 1,
      firstLoad: true
    }
  },
  watch: {
    options: {
      handler() {
        if (!this.firstLoad) this.getOrder()
      },
      deep: true,
    },
    async notificationId(val) {
      if (val) await this.initDataNoti()
    }
  },
  methods: {
    async initDataNoti() {
      const query = this.$route.query
      if (query.notification && this.notificationId) {
        const noti = await this.$store.dispatch('notification/getNotification', query.noti)
        this.currentData = {
          ...noti.reference_data
        }
        this.isNotification = true
      }
      await this.getOrder()
      this.firstLoad = false
    },
    async addToTasking() {
      this.$refs.createOrder.openDialog({
        geojson: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                coordinates: [105.81550696477707, 21.00256556842219],
                type: 'Point',
              },
            },
          ],
        },
        dataType: undefined,
        name: undefined,
        id: undefined,
      })
    },
    async getOrder() {
      try {
        this.loading = true
        // this.changeData = []
        const res = await getTaskingOrders({
          projectId: this.$route.params.id,
          payload: {
            page: this.page,
            per_page: this.options.itemsPerPage,
            sort: this.options.sortBy[0]
              ? !this.options.sortDesc[0]
                ? this.options.sortBy[0]
                : `-${this.options.sortBy[0]}`
              : undefined,
          },
        })
        this.orders = res.data.data.map(order => {
          order.created_at = ConvertDate.dateTimeFormatted(order.created_at)
          return order
        })
        if (this.isNotification) {
          const order = this.orders.find(order => order.uuid == this.currentData.tasking_order.uuid)
          if (order) this.openDetail(order, true)
        }
        this.totalPage = res.data.last_page
        this.totalData = res.data.total
      } catch (e) {
        console.log('Error when get order', e)
      } finally {
        this.loading = false
      }
    },
    openDetail(order, withHistory=false) {
      if (order.image_source === 'Skysat') this.$refs.planetOrders.openDialog(order, withHistory)
    },
  },
  async mounted() {
    await this.initDataNoti()
    // window.open('https://www.planet.com/tasking/', '_blank')
    // history.back()
    // this.getOrder()
    // this.openDetail()
  },
}
</script>

<style scoped></style>
