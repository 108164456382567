<template>
  <v-dialog v-model="orderDialog" persistent width="1440">
    <v-card
      :disabled="loading"
      class="custom-card-bg-gradient overflow-y-hidden overflow-x-hidden pa-2"
      height="90vh"
      style="position: relative"
      width="100%"
    >
      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <DialogHeader :is-close="true" :title="'Orders'" @close="orderDialog = false" />
      <v-layout class="overflow-y-hidden px-3 pb-3" style="height: calc(100% - 70px)">
        <v-layout class="fill-height mr-2">
          <CardMapView v-if="showMap" ref="mapPreview" :inspect="false" :show-layer-control="false" />
        </v-layout>
        <div class="fill-height ml-2" style="flex: none; width: 400px; border: 1px solid #2F3241; border-radius: 8px">
          <v-layout v-if="data && data.cap" class="fill-height overflow-y-auto pa-3" column style="font-size: 14px">
            <div>
              <v-layout>
                <h3>
                  Info
                </h3>
              </v-layout>
            </div>
            <div class="py-2">
              <v-layout>
                Cloud coverage:
                <v-spacer />
                {{ data.cap.cloud_cover * 100 }} %
              </v-layout>
            </div>
            <div class="py-2">
              <v-layout>
                Acquired on:
                <v-spacer />
                {{ data.cap.acquired_time }}
              </v-layout>
            </div>
            <div class="py-2">
              <v-layout>
                Published to customer:
                <v-spacer />
                {{ data.cap.published_time }}
              </v-layout>
            </div>
            <div class="py-2">
              <v-layout>
                Order:
                <v-spacer />
                {{ data.cap.order_name }}
              </v-layout>
            </div>
            <div class="py-2">
              <v-layout>
                Order type:
                <v-spacer />
                IMAGE
              </v-layout>
            </div>
            <div class="py-2">
              <v-layout>
                Satellite:
                <v-spacer />
                {{ data.cap.strip_id.slice(0, data.cap.strip_id.indexOf('_')) }}
              </v-layout>
            </div>
            <div class="pt-3">
              <v-layout>
                <h3>
                  List order
                </h3>
                <v-spacer />
                <v-btn color="success" icon small @click="getListOrder">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </v-layout>
            </div>
            <v-data-table :headers="headers" :items="orders" :items-per-page="5" class="elevation-1">
              <template #[`item.name`]="{item}">
                <div>
                  {{ item.name }}
                </div>
                <div style="font-size: 11px; color: whitesmoke">
                  Created on: {{ ConvertDate.dateTimeFormatted(item.created_on) }}
                </div>
              </template>
              <template #[`item.state`]="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      :color="item.state === 'success' ? 'success' : 'primary'"
                      class="text-capitalize"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.state }}
                    </v-chip>
                  </template>
                  <span>{{ item.last_message }}</span>
                </v-tooltip>
              </template>
              <template #[`item.action`]="{item}">
                <v-tooltip v-if="item.state === 'success'" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" @click="downloadImage(item)">
                      <v-icon small>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Download</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-spacer />
            <div>
              <v-layout align-center justify-center="">
                <v-btn color="primary" width="100%" @click="createOrder">
                  Create Order
                </v-btn>
              </v-layout>
            </div>
          </v-layout>
        </div>
      </v-layout>
    </v-card>
    <MakeOrder ref="makeOrder" @refresh="getListOrder" />
    <ListResult ref="listResult" />
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import MakeOrder from '@/views/order/MakeOrder.vue'
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import sleep from '@/utils/sleep'
import bbox from '@turf/bbox'
import PlanetApi from '@/utils/planet/planetTaskingApi'
import ConvertDate from '@/utils/convertDate'
import ListResult from '@/views/order/ListResult.vue'

export default {
  components: { ListResult, CardMapView, MakeOrder, DialogHeader },
  data() {
    return {
      orderDialog: false,
      loading: false,
      data: undefined,
      showMap: false,
      orders: [],
      selectedImage: undefined,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'State',
          align: 'start',
          sortable: false,
          value: 'state',
        },
        {
          text: 'Action',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
    }
  },
  computed: {
    ConvertDate() {
      return ConvertDate
    },
  },
  watch: {
    orderDialog(val) {
      if (!val) this.showMap = false
    },
  },
  methods: {
    openDialog(data) {
      this.data = data
      this.orderDialog = true
      this.initData()
    },
    async initData() {
      await this.getImageData()
      await this.displayMap()
      await this.getListOrder()
    },
    async getImageData() {
      try {
        this.loading = true
        this.selectedImage = await PlanetApi.getImage(this.data.image.ids[0])
        this.selectedImage.ids = this.data.image.ids
        delete this.selectedImage.id
      } catch (e) {
        this.loading = false
      } finally {
      }
    },
    async displayMap() {
      await sleep(500)
      this.showMap = true
      await sleep(1000)
      let geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: this.data.geometry,
          },
        ],
      }
      let bounds = bbox(geojson)
      this.data.image.ids.forEach(item => {
        this.$refs.mapPreview.addToMap({
          tile_info: {
            type: 'images',
            tile_url: `https://tiles2.planet.com/data/v1/SkySatCollect/${item}/{z}/{x}/{y}.png?api_key=${PlanetApi.getApikey()}`,
            bbox: bounds,
          },
          name: 'image',
        })
      })
      this.$refs.mapPreview.setLayer('AOI', geojson)
      this.$refs.mapPreview.zoomTo(bounds)
    },
    async getListOrder() {
      try {
        this.loading = true
        let res = await PlanetApi.getListOrder()
        this.orders = res.data.orders
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    createOrder() {
      this.$refs.makeOrder.openDialog(this.selectedImage, this.data.geometry)
    },
    async downloadImage(order) {
      try {
        this.loading = true
        let downloadMenu = []
        const resDetail = await PlanetApi.getOrder(order.id)
        resDetail.data._links.results.forEach(result => {
          downloadMenu.push({
            name: result.name.split('/').pop(),
            link: result.location,
          })
        })
        if (downloadMenu.length) this.$refs.listResult.openDialog(downloadMenu)
        else this.$store.commit('message/SHOW_ERROR', 'No data')
        // window.open(resDetail.data._links.results[0].location, '_blank')
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
