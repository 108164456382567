<template>
  <v-dialog v-model="dialog" fullscreen persistent>
    <v-card
      :disabled="loading"
      class="custom-card-bg-gradient overflow-y-hidden overflow-x-hidden pa-2"
      height="90vh"
      width="100%"
    >
      <DialogHeader :is-close="true" :title.sync="title" @close="closeDialog" />

      <v-layout class="overflow-y-hidden" style="height: calc(100% - 66px)">
        <v-layout class="px-3 py-0 overflow-y-auto" column fill-height>
          <div class="py-3" style="flex: none; height: fit-content; width: 100%">
            <v-row>
              <v-col class="pr-1" cols="12" lg="3" sm="6">
                <v-text-field
                  v-model="filter.name__icontains"
                  dense
                  hide-details
                  label="Search by name"
                  outlined
                  prepend-inner-icon="mdi-magnify"
                  @input="debounceInput"
                ></v-text-field>
              </v-col>
              <v-col class="px-1" cols="12" lg="3" md="6">
                <v-select
                  v-model="filter.status__in"
                  :items="status"
                  clearable
                  dense
                  hide-details
                  item-text="name"
                  item-value="value"
                  label="Status"
                  multiple
                  outlined
                  placeholder="Status"
                  @change="searchOrder"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0" small>
                      <v-icon :color="item.color" class="mr-2" small>mdi-circle</v-icon>
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ filter.status__in.length - 1 }} items)
                    </span>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-layout align-center class="py-2">
                        <div class="mr-3">
                          <v-icon :color="data.item.color" size="16">mdi-circle</v-icon>
                        </div>
                        {{ data.item.name }}
                      </v-layout>
                    </template>
                  </template>
                </v-select>
              </v-col>
              <v-col class="px-1" cols="12" lg="3" md="6">
                <DatePicker
                  v-model="filter.start_time__gte"
                  clearable
                  hide-details
                  label="Start time"
                  prepend-inner-icon="mdi-calendar-month-outline"
                  @input="searchOrder"
                />
              </v-col>
              <v-col class="pl-1" cols="12" lg="3" md="6">
                <DatePicker
                  v-model="filter.end_time__lte"
                  clearable
                  hide-details
                  label="End time"
                  prepend-inner-icon="mdi-calendar-month-outline"
                  @input="searchOrder"
                />
              </v-col>
            </v-row>
          </div>
          <v-layout fill-height style="overflow-y: auto; flex: 1; min-height: 300px">
            <v-data-table
              :headers="headers"
              :items="orders"
              :loading="loading"
              :options.sync="options"
              :server-items-length="totalData"
              class="elevation-1"
              :item-class="rowClass"
              fixed-header
              height="100%"
              hide-default-footer
              style="width: 100%"
              @click:row="openDetail"
            >
              <template #[`item.status`]="{item}">
                <v-chip :color="statusColor(item.status)" label small>
                  {{ item.status }}
                </v-chip>
              </template>
              <template #[`item.start_time`]="{item}">
                {{ transformDate(item.start_time) }}
              </template>
              <template #[`item.end_time`]="{item}">
                {{ transformDate(item.end_time) }}
              </template>
            </v-data-table>
          </v-layout>
          <div style="flex: none; width: 100%">
            <div class="mt-3 pb-2 d-flex justify-end">
              <v-pagination
                v-model="page"
                :length="totalPage"
                :total-visible="10"
                circle
                @input="getOrders"
              ></v-pagination>
            </div>
          </div>
        </v-layout>
        <div
          v-if="isDetail"
          class="px-2"
          style="flex: none; min-width: 360px; width: 360px; min-height: 100%; height: 100% ;border-left: 1px solid #2F3241; overflow-y: hidden; position: relative"
        >
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-layout column fill-height>
            <div style="flex: none; width: 100%; height: 54px; border-bottom: 1px solid #2F3241">
              <v-layout align-center class="pt-1" fill-height>
                <v-btn-toggle v-model="tab" color="deep-purple accent-3" group tile>
                  <v-btn value="info">
                    Info
                  </v-btn>

                  <v-btn value="cap">
                    Captures
                  </v-btn>

                  <v-btn value="his">
                    History
                  </v-btn>
                </v-btn-toggle>
                <v-spacer />
                <v-btn icon small @click="isDetail = false">
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-layout>
            </div>
            <v-divider />
            <v-layout v-if="tab === 'info'" class="pt-3 overflow-y-auto" column fill-height>
              <v-layout
                v-for="(item, key) of selectedOrder.info"
                v-if="!key.includes('geometry')"
                :key="key"
                class="px-1"
                style="font-size: 14px"
              >
                {{ key.replaceAll('_', ' ') }}:
                <v-spacer />
                <b>{{ item }}</b>
              </v-layout>
            </v-layout>
            <v-layout v-if="tab === 'cap'" class="pt-3 overflow-y-auto" column fill-height>
              <v-card
                v-for="(item, key) of selectedOrder.captures"
                :key="key"
                class="custom-card-bg-1 pa-1 mb-1 px-2"
                style="font-size: 14px"
              >
                <v-layout align-center fill-height>
                  <div
                    class="mr-3 custom-card-bg-1"
                    style="width: 80px; height: 80px; border: 1px solid #2F3241; border-radius: 8px; padding: 4px"
                  >
                    <div
                      class="custom-card-bg-gradient"
                      style="width: 100%; height: 100%;  border-radius: inherit; padding: 2px"
                    >
                      <v-img :lazy-src="item.thumbnail_url" :src="item.thumbnail_url">
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                  </div>
                  <v-layout column fill-height>
                    <h4>{{ item.imge_idd }}</h4>
                    <div>Published: {{ transformDate(item.published_time) }}</div>
                    <div>Cloud cover: {{ item.cloud_cover }}</div>
                    <div>
                      <v-layout align-center>
                        Evaluation: {{ item.evaluation }}
                        <v-spacer />
                        <v-btn v-if="item.evaluation === 'SUCCESS'" icon small @click="makeOrderDownload(item)">
                          <v-icon small>mdi-cart-outline</v-icon>
                        </v-btn>
                      </v-layout>
                    </div>
                  </v-layout>
                </v-layout>
              </v-card>
            </v-layout>
            <v-layout v-if="tab === 'his'" class="pt-3 overflow-y-auto" column fill-height justify-start>
              <v-timeline class="custom-time-line">
                <v-timeline-item v-for="(item, index) in selectedOrder.histories" :key="index" class="pb-4" right small>
                  <span slot="opposite" style="font-size: 12px">
                    {{ !index || item.date !== selectedOrder.histories[index - 1].date ? item.date : '' }}
                  </span>
                  <v-layout column style="font-size: 12px">
                    <div>{{ transformDate(item.created_time) }}</div>
                    <div>
                      {{ item.message }}
                    </div>
                    <div>{{ item.data ? item.data.assessment_result : '' }}</div>
                  </v-layout>
                </v-timeline-item>
              </v-timeline>
            </v-layout>
          </v-layout>
        </div>
      </v-layout>
      <PlanetOrderImage ref="orderImage" />
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import planetTaskingApi from '@/utils/planet/planetTaskingApi'
import DatePicker from '@/components/DatePicker.vue'
import STATUS from '@/constants/planet-tasking-status'
import { debounce } from 'lodash'
import ConvertDate from '@/utils/convertDate'
import PlanetOrderImage from '@/views/order/PlanetOrderImage.vue'

export default {
  components: { PlanetOrderImage, DatePicker, DialogHeader },
  data() {
    return {
      tab: 'info',
      defaultTitle: 'Order',
      title: '',
      isDetail: false,
      selectedOrder: {
        info: {},
        captures: [],
        histories: [],
      },
      dialog: false,
      loading: false,
      totalPage: 0,
      totalData: 0,
      orders: [],
      filter: {
        name__icontains: undefined,
        start_time__gte: undefined,
        end_time__lte: undefined,
        status__in: [],
      },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Contract number', value: 'pl_number' },
        { text: 'Product name', value: 'product' },
        { text: 'Status', value: 'status' },
        { text: 'Start time', value: 'start_time' },
        { text: 'End time', value: 'end_time' },
      ],
      options: {},
      page: 1,
      status: STATUS,
      firstLoad: true
    }
  },
  watch: {
    options: {
      handler() {
        if (!this.firstLoad) this.getOrders()
      },
      deep: true,
    },
  },
  mounted() {
    // window.open('https://www.planet.com/tasking/', '_blank')
    // history.back()
  },
  methods: {
    rowClass() {
      return 'clickable-base'
    },
    closeDialog() {
      this.dialog = false
      this.isDetail = false
    },
    async openDialog(order, withHistory = false) {
      this.dialog = true
      this.orders = []
      this.currentOrder = order
      if (this.currentOrder?.name) this.title = `${this.defaultTitle} - ${this.currentOrder.name}`
      await this.getOrders(withHistory)
      this.firstLoad = false
    },
    debounceInput: debounce(function(e) {
      this.searchOrder()
    }, 500),
    searchOrder() {
      this.page = 1
      this.getOrders()
    },
    async getOrders(withHistory=false) {
      try {
        this.loading = true
        let params = {
          offset: (this.page - 1) * 15,
          limit: 15,
          ordering:
            this.options.sortBy && this.options.sortBy[0]
              ? !this.options.sortDesc[0]
                ? this.options.sortBy[0]
                : `-${this.options.sortBy[0]}`
              : null,
        }
        let tmpFilter = JSON.parse(JSON.stringify(this.filter))
        params = { ...params, ...tmpFilter }
        for (const paramsKey in params) {
          if (params[paramsKey] === '' || params[paramsKey] === null || params[paramsKey] === undefined) {
            delete params[paramsKey]
          }
        }
        const stringParam = new URLSearchParams(params).toString()
        const res = await planetTaskingApi.getTaskingOrders(`${this.currentOrder.detail_url}&` + stringParam)
        this.orders = res.data.results
        if (withHistory && this.orders.length > 0) {
          this.openDetail(this.orders[0], true)
        }
        this.totalPage = Math.round(res.data.count / 10)
        this.totalData = res.data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async openDetail(data, withHistory = false) {
      try {
        this.loading = true
        this.tab = withHistory == true ? 'his' : 'info'
        this.selectedOrder.info = data
        this.isDetail = true
        await this.getCaptures(data.id)
        await this.getHistory(data.id)
      } catch (e) {
        console.warn('Error when get data planet order', e)
      } finally {
        this.loading = false
      }
    },
    async getCaptures(id) {
      try {
        const res = await planetTaskingApi.getTaskingOrders(
          `/tasking/v2/captures?order_id=${id}&ordering=-created_time&status__in=PUBLISHED&limit=1000`,
        )
        this.selectedOrder.captures = res.data.results
        this.selectedOrder.captures.forEach(val => {
          val.thumbnail_url = `https://tiles.planet.com/data/v1/SkySatCollect/${val.item_ids.toString()}/thumb?format=png&fit=true&api_key=${planetTaskingApi.getApikey()}`
        })
      } catch (e) {
      } finally {
      }
    },
    async getHistory(id) {
      try {
        const res = await planetTaskingApi.getTaskingOrders(
          `/tasking/v2/order-history?order_id=${id}&ordering=-created_time&limit=1000`,
        )
        this.selectedOrder.histories = res.data.results
        this.selectedOrder.histories.forEach(val => {
          val.date = ConvertDate.dateFormatted(val.created_time)
        })
      } catch (e) {
      } finally {
      }
    },
    async makeOrderDownload(cap) {
      try {
        let data = {
          image: {
            ids: cap.item_ids,
            properties: {
              item_type: 'SkySatCollect',
              acquired: cap.published_time,
            },
          },
          geometry: cap.captured_area,
          cap: cap,
        }

        this.$refs.orderImage.openDialog(data)
      } catch (e) {
      } finally {
      }
    },
    statusColor(status) {
      let currentStatus = this.status.find(val => val.value === status)
      if (currentStatus) return currentStatus.color
      else return 'transparent'
    },
    transformDate(date) {
      return ConvertDate.dateTimeFormatted(date)
    },
  },
}
</script>
