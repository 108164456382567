const STATUS = [
  {
    name: 'Received',
    value: 'RECEIVED',
    color: '#f8d488',
  },
  {
    name: 'Pending',
    value: 'PENDING',
    color: '#ffca55',
  },
  {
    name: 'Requested',
    value: 'REQUESTED',
    color: '#6c78fd',
  },
  {
    name: 'In Progress',
    value: 'IN_PROGRESS',
    color: '#5C6AFF',
  },
  {
    name: 'Finalizing',
    value: 'FINALIZING',
    color: '#2AB8F0',
  },
  {
    name: 'FulFilled',
    value: 'FULFILLED',
    color: '#008610',
  },
  {
    name: 'Expired',
    value: 'EXPIRED',
    color: '#ff6060',
  },
  {
    name: 'Reject',
    value: 'REJECT',
    color: '#ff6060',
  },
  {
    name: 'Failed',
    value: 'FAILED',
    color: '#ff6060',
  },
  {
    name: 'Cancelled',
    value: 'CANCELLED',
    color: '#8c8c8c',
  },
  {
    name: 'Pending Cancellation',
    value: 'PENDING_CANCELLATION',
    color: '#8c8c8c',
  },
]
export default STATUS
