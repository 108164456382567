<template>
  <v-dialog v-model="downloadDialog" persistent width="520">
    <v-card
      :disabled="loading"
      class="custom-card-bg-gradient overflow-y-hidden overflow-x-hidden pa-2"
      style="position: relative"
    >
      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <DialogHeader :is-close="true" :title="'Image results'" @close="downloadDialog = false" />
      <v-layout align-center class="overflow-y-hidden px-3 pb-3" justify-center>
        <v-list dense width="480">
          <v-list-item v-for="(item, i) in links" :key="i">
            <v-list-item-content>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title
                    class="text-truncate"
                    v-bind="attrs"
                    v-on="on"
                    v-text="item.name"
                  ></v-list-item-title>
                </template>
                <span>{{ item.name }}</span>
              </v-tooltip>
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn :href="item.link" icon small target="_blank">
                <v-icon small>
                  mdi-download
                </v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'

export default {
  components: { DialogHeader },
  data() {
    return {
      downloadDialog: false,
      loading: false,
      links: [],
    }
  },
  methods: {
    openDialog(links) {
      this.links = links
      this.downloadDialog = true
    },
  },
}
</script>

<style scoped></style>
